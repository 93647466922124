import React from 'react'
import moment from 'moment';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Axios from '../../../includes/axios';
import UILIB from '../../../common-objects/Lib'
import Utils from '../../../config/utils'
export default class LocationsIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            graphData: [],
            loading: true,
            totalMonths: 6
        }
        this.getStats = this.getStats.bind(this);
    }

    componentDidMount() {
        if (!this.props.invalidDateRange)
          this.getStats(false, this.props);
    }
    componentWillUnmount() {

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate) this.getStats(true, nextProps);
    }
    getStats(forced, props) {
        var self = this;
        if (forced) this.setState({ loading: true })
        var qs = "";

        if (props.startDate && props.endDate) qs = `?start=${props.startDate}&end=${props.endDate}`

        Axios.get(`/dashboard/locationstats` + qs).then(_res => {
            self.setState({ graphData: _res.data.results, loading: false })
        })
    }

    render() {
        if (this.props.invalidDateRange) {
            return <div></div>
        }
        if (this.state.loading) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        var subSites = JSON.parse(JSON.stringify(this.props.subSites))
        subSites = subSites.sort((a, b) => {
            var nameA = (a.name + " " + a.town + " " + a.postcode);
            var nameB = (b.name + " " + b.town + " " + b.postcode);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;

            }
            // names must be equal
            return 0;

        })

        return <div>
            <div style={{ textAlign: "right", marginBottom: "15px" }}>Total Devices: {this.state.graphData.length}</div>
                <Row>
                    {subSites.map((site, index) => {
                        var accNo = site.reference;
                        var ourStats = this.state.graphData.filter(gd => String(gd.AccNo).trim() === String(accNo))
                        var totalMono = 0;
                        var totalColour = 0;
                        var totalPages = 0;
                        var totalBdCalls = 0;
                        var totalColPct = 0;
                        var totalUptimePct = 0;

                        return <Col xs={12} sm={12} md={12} lg={12} key={"site_" + index}>
                            <div className="paper">
                                <h2>{site.name}. {site.town}. {site.postcode}</h2>
                                <div className="standardTable">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                    { (ourStats.length > 0)  && <thead>
                                            <tr>
                                                <th>Model of Device</th>
                                                <th>Serial Number</th>
                                                <th>Asset Number</th>
                                                <th>Location</th>
                                                <th>Total Mono</th>
                                                <th>Total Colour</th>
                                                <th>Total Pages</th>
                                                <th>Average Monthly Vol</th>
                                                <th>Recommended Monthly</th>
                                                <th>Colour %</th>
                                                <th>No. of BD calls</th>
                                            </tr>
                                        </thead> 
                                    }

                                        {(ourStats.length <= 0) && <tr>
                                            <td colSpan="11" align="center">
                                                No devices statistics found for this location
                                        </td>
                                        </tr>}
                                        {(ourStats && ourStats.length > 0) && ourStats.map((statLine, statIndex) => {
                                            totalMono += Number(statLine.MonoTotal);
                                            totalColour += Number(statLine.ColourTotal);
                                            totalPages += Number(statLine.VolumesTotal);
                                            totalBdCalls += Number(statLine.bkdCalls);
                                            totalColPct += Number(statLine.colPct);
                                            totalUptimePct += Number(statLine.uptimePCT);
                                            return <tr key={index + "_" + statIndex}>
                                                <td>{statLine.deviceName}</td>
                                                <td>{statLine.serialNo}</td>
                                                <td>{statLine.RefNo}</td>
                                                <td>{statLine.DeviceLocation}</td>
                                                <td>{statLine.MonoTotal}</td>
                                                <td>{statLine.ColourTotal}</td>
                                                <td>{statLine.VolumesTotal}</td>
                                                <td>{Utils.formatNumber(statLine.avgMonthlyVol, 0)}</td>
                                                <td>{statLine.deviceMaxMonthly}</td>
                                                <td>{Utils.formatNumber(statLine.colPct, 2)}%</td>
                                                <td>{statLine.bkdCalls}</td>
                                            </tr>
                                        })}
                                            { (ourStats.length > 0)  && <tr style={{ color: "#999999", fontSize: "12px" }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Totals:</td>
                                                <td>{totalMono}</td>
                                                <td>{totalColour}</td>
                                                <td>{totalPages}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{totalBdCalls}</td>
                                            </tr> 
                                            }
                                            { (ourStats.length > 0) && <tr style={{ color: "#999999", fontSize: "12px" }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>Average:</b></td>
                                                <td>{Utils.formatNumber(totalMono / ourStats.length, 0)}</td>
                                                <td>{Utils.formatNumber(totalColour / ourStats.length, 0)}</td>
                                                <td>{Utils.formatNumber(totalPages / ourStats.length, 0)}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{Utils.formatNumber(totalColPct / ourStats.length, 2)}%</td>
                                                <td>{Utils.formatNumber(totalBdCalls / ourStats.length, 0)}</td>
                                            </tr> 
                                            }                
                                    </table>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>
        </div>
    }
}
