import React from 'react'
import moment from 'moment';
import Axios from '../../../includes/axios';
import UILIB from '../../../common-objects/Lib'
import Utils from '../../../config/utils'
export default class TableServicePerformanceStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            graphData: [],
            loading: true
        }
        this.getStats = this.getStats.bind(this);
    }

    componentDidMount() {
        this.getStats(false, this.props);
    }
    componentWillUnmount() {

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate) this.getStats(true, nextProps);
    }

    getStats(forced, props) {
        var self = this;
        if (forced) this.setState({ loading: true })
        var self = this;
        var qs = "";
        if (props.startDate && props.endDate) qs = `?start=${props.startDate}&end=${props.endDate}`
        Axios.get(`/dashboard/statsServicePerformance` + qs).then(_res => {
            self.setState({ graphData: _res.data.results, loading: false })
        })
    }

    render() {
        if (this.state.loading) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <div className="standardTable">
            <table width="100%" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Courtesy Visits</th>
                        <th>IT Calls</th>
                        <th>Breakdown Calls</th>
                        <th>RTF</th>
                        <th>Total Calls</th>

                    </tr>
                </thead>
                {this.state.graphData.map((row, index) => {
                    return <tr key={"tsps_" + index}>
                        <td>{row.date}</td>
                        <td>{row.CourtesyVisits}</td>
                        <td>{row.ITCalls}</td>
                        <td>{row.Breakdowns}</td>
                        <td>{row.RTFs}</td>
                        <td>{row.AllCalls}</td>

                    </tr>
                })}
            </table>
        </div>
    }
}
