import React from 'react';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, BrowserHistory } from "react-router-dom";
import WelcomePage from '../frontPage/welcome/welcome'
import PasswordReminder from '../frontPage/welcome/passwordReminder'
import ResetPassword from '../frontPage/welcome/resetPassword'
import AccountRegistration from '../frontPage/welcome/accountRegistration'
import bg1 from '../assetts/images/frontPage/bg1.jpg'
import bg2 from '../assetts/images/frontPage/bg2.jpg'
import bg3 from '../assetts/images/frontPage/bg3.jpg'
import bg4 from '../assetts/images/frontPage/bg4.jpg'
import bg5 from '../assetts/images/frontPage/bg5.jpg'
import bg6 from '../assetts/images/frontPage/bg6.jpg'


import soc1 from '../assetts/images/frontPage/faceBook.png';
import soc2 from '../assetts/images/frontPage/twitter.png';
import soc3 from '../assetts/images/frontPage/instaGram.png';
import soc4 from '../assetts/images/frontPage/linkedin.png';
import GroupLogo from '../assetts/images/logos/agilicoBlueLogo.png';

import UILIB from '../common-objects/Lib'
import queryString from 'query-string';
import Axios from 'axios';
import Connections from '../config/connections'

import moment from 'moment'

export default class FrontPageTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cPic: 1,
            loggedIn: false,
            account: {}
        }

        this.changePic = this.changePic.bind(this);
        this.goLogin = this.goLogin.bind(this);
        this.checkLogged = this.checkLogged.bind(this);
    }

    componentWillMount() {

        var self = this;
        const query = queryString.parse(location.search);

        if (query && (String(query.authtoken).length & String(query.oid).length))  {
            return Axios.post(Connections.apiUrl + "/apikey", { key: query.authtoken, oid: query.oid, ad: query.ad }).then((response) => {
                sessionStorage.setItem("customerContext", response.data.context)
                localStorage.setItem(`accessToken_${response.data.context}`, response.data.accessToken)
                self.props.history.push('/overview')
            }).catch(err => { console.log(err) })
        }

        setTimeout(this.changePic, 10000)
        this.checkLogged(sessionStorage.getItem("customerContext"));
    }

    checkLogged(context) {
        if (localStorage.getItem(`accessToken_${context}`)) {
            this.props.history.push('/overview')
        }
    }

    changePic() {
        var self = this;
        var state = this.state;
        state.cPic++;
        if (state.cPic > 6) state.cPic = 1;
        this.setState(state, () => {
            setTimeout(self.changePic, 10000)
        });
    }


    goLogin() {
        this.props.history.push('/login')
    }

    render() {
        var theHistory = this.props.history;

        var bgImage = bg1;
        switch (this.state.cPic) {
            case 1:
                bgImage = bg1;
                break;
            case 2:
                bgImage = bg1;
                break;
            case 3:
                bgImage = bg1;
                break
            case 4:
                bgImage = bg1;
                break
            case 5:
                bgImage = bg5;
                break
            case 6:
                bgImage = bg6;
                break
        }

        return <div className="fpOuter" style={{ backgroundImage: "url(" + bgImage + ")" }
        }>
            <Container fluid={true} style={{ margin: "0px" }}>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={4} className="fpLeftMenu">
                        <div className="fpLeftMenuInner">

                            <img src={GroupLogo} className="mar-b40 logo" alt="Agilico Customer Portals" title="Agilico Customer Portals" style={{ cursor: "pointer", width: "200px" }} onClick={() => { this.props.history.push("/") }} />

                            <Switch>
                                <Route path="/passwordreminder" component={PasswordReminder} history={theHistory} />
                                <Route path="/resetpassword" component={ResetPassword} history={theHistory} />
                                <Route path="/accountRegistration" component={AccountRegistration} history={theHistory} />
                                <Route exact path="/" component={WelcomePage} history={theHistory} />
                                <Redirect to="/" push />
                            </Switch>


                            <Visible xs sm>
                                <div className="fpSocialHolder mar-b20" style={{ position: "relative", textAlign: "center", bottom: "auto", right: "auto" }}>
                                    <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc1 + ")" }} alt="Facebook" title="Facebook"></div>
                                    <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc2 + ")" }} alt="Twitter" title="Twitter"></div>
                                    <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc3 + ")" }} alt="Instagram" title="Instagram"></div>
                                </div>
                                <div style={{ fontSize: "12px", textAlign: "center" }}>
                                    &copy;{moment().format('YYYY')} Agilico<br />

                                </div>
                            </Visible>
                            <Hidden xs sm>
                                <div style={{ position: "absolute", bottom: "20px", fontSize: "12px" }}>
                                    &copy;{moment().format('YYYY')} Agilico<br />

                                </div>
                            </Hidden>

                        </div>

                    </Col>
                    <Hidden xs sm>
                        <Col xs={12} sm={12} md={6} lg={8}  >

                            <div className="fpSocialHolder">
                                <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc1 + ")" }} alt="Facebook" title="Facebook" onClick={() => { window.open('https://www.facebook.com/agilico/', '_blank'); }}></div>
                                <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc2 + ")" }} alt="Twitter" title="Twitter" onClick={() => { window.open('https://twitter.com/teamagilico', '_blank'); }}></div>
                                <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc3 + ")" }} alt="Instagram" title="Instagram" onClick={() => { window.open('https://www.instagram.com/teamagilico/', '_blank'); }}></div>
                                <div className="socialHolder mar-r10" style={{ backgroundImage: "url(" + soc4 + ")" }} alt="LinkedIn" title="LinkedIn" onClick={() => { window.open('https://www.linkedin.com/company/agilico-south/', '_blank'); }}></div>
                            </div>
                        </Col>
                    </Hidden>

                </Row>
            </Container>
        </div>
    }
}