import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid
} from 'recharts';
import moment from 'moment';
import Axios from '../../../includes/axios';
import UILIB from '../../../common-objects/Lib'

export default class GraphRTFCalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphData: [],
            loading: true
        }
        this.getStats = this.getStats.bind(this);
    }
    componentDidMount() {
        this.getStats(false, this.props);
    }
    componentWillUnmount() {

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate) this.getStats(true, nextProps);
    }
    getStats(forced, props) {
        var self = this;
        if (forced) this.setState({ loading: true })
        var self = this;
        var qs = "";
        if (props.startDate && props.endDate) qs = `?start=${props.startDate}&end=${props.endDate}`
        Axios.get(`/dashboard/graphRTFCalls` + qs).then(_res => {
            self.setState({ graphData: _res.data.results, loading: false })
        })
    }
    render() {
        if (this.state.loading) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <div>
            <ResponsiveContainer height={150}>

                <BarChart data={this.state.graphData} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <XAxis dataKey="date" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: 'black', fontFamily: "Roboto", fontWeight: 600 }} interval={0} />
                    <YAxis />
                    <Tooltip
                        labelStyle={{ fontSize: 10, color: 'black', fontFamily: "Roboto", fontWeight: 600 }}
                        itemSyle={{ fontSize: 10, fontFamily: "Roboto", fontWeight: 600 }}
                        cursor={false}
                    />

                    <Bar type="monotone" dot={false} dataKey="RTFs" strokeWidth="0" fill={"#4682B4"} stroke={"#4682B4"} fillOpacity={0.8} domain={[0, 20000]} />
                    {/* <Bar type="monotone" dot={false} dataKey="Breakdowns" strokeWidth="0" fill={"orange"} stroke={"orange"} fillOpacity={0.8} /> */}
                </BarChart>
            </ResponsiveContainer>
        </div>
    }
}
