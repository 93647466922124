import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, BrowserHistory } from "react-router-dom";
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import SessionHelpers from '../session/index'
import UILIB from '../common-objects/Lib'

import Header from '../cp/includes/header'
import OverViewPage from '../cp/overview/overview'
import DashBoardPage from '../cp/dashboard/dashboard'
import SupportPage from '../cp/support/index'
import GuidesPage from '../cp/guides/guides'
import KpaxPage from '../cp/kpax/kpax'
import AdminPage from '../cp/admin/admin'
import AccountPage from '../cp/account/account'
import TelecomsAndIt from '../cp/telecomsandit'

import Axios from '../includes/axios';

export default class EventTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
            customers: {},
            currCustomer: {},
            loadedAccount: false,
            subSites: []
        }
        let timer;
        this.getAccount = this.getAccount.bind(this);
        this.changeCustomer = this.changeCustomer.bind(this);
        this.accountChecker = this.accountChecker.bind(this);
    }

    componentWillMount() {
        this.getAccount();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    accountChecker() {
        var self = this;
        Axios.get("/customerPerson").then((response) => {
            self.timer = setTimeout(self.accountChecker, 30000)
        }).catch(err => {
            console.log(err);
            SessionHelpers.logout(sessionStorage.getItem("customerContext"));
        })
    }

    getAccount() {
        var isLogged = SessionHelpers.checkAccount(sessionStorage.getItem("customerContext"));

        if (!isLogged) {
            this.props.history.push("/")
            return;
        }
        SessionHelpers.getAccount().then(acc => {

            var currCustomer = {};
            var self = this;
            currCustomer = acc.customer.customers.find(cust => cust.id == acc.customer.customer[0].id);


            this.setState({
                account: acc.customer.customerPerson,
                customers: acc.customer.customers,
                currCustomer: currCustomer,
                subSites: acc.subSites,
                loadedAccount: true,
                from360: acc.customer.from360
            }, () => {
                self.timer = setTimeout(self.accountChecker, 1000)
            })
            return;
        }).catch(err => {
            console.log(err);
            SessionHelpers.logout(sessionStorage.getItem("customerContext"));
            return;
        })

    }

    changeCustomer(newPlace) {
        var currCustomer = this.state.currCustomer;
        var newCustomer = this.state.customers.find(p => p.id == newPlace)
        Axios.get("/customer/change/" + newCustomer.id).then((response) => {
            sessionStorage.setItem("customerContext", newCustomer.id);
            localStorage.setItem(`accessToken_${newCustomer.id}`, response.data.accessToken)
            window.location.reload();
        }).catch(err => {

        })

    }

    render() {
        if (!this.state.loadedAccount) return <Row><Col xs={12} align="center"><UILIB.LoadingIcon /></Col></Row>

        return <Container fluid={true} style={{ margin: "0px", marginBottom: "20px" }}>
            <Header history={this.props.history} account={this.state.account} customers={this.state.customers} currCustomer={this.state.currCustomer} changeCustomer={this.changeCustomer} subSites={this.state.subSites} from360={this.state.from360} />
            <Switch>
                <Route path="/overview/" render={(props) => { return <OverViewPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/support/" render={(props) => { return <SupportPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/dashboard/" render={(props) => { return <DashBoardPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/guides/:contentId?" render={(props) => { return <GuidesPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/kpax/" render={(props) => { return <KpaxPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/admin/" render={(props) => { return <AdminPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} customers={this.state.customers} /> }} />
                <Route path="/account/" render={(props) => { return <AccountPage {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Route path="/telecomsandit/" render={(props) => { return <TelecomsAndIt {...props} account={this.state.account} history={this.props.history} currCustomer={this.state.currCustomer} subSites={this.state.subSites} /> }} />
                <Redirect to="/" push />
            </Switch>
        </Container>
    }
}